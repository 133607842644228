import * as React from "react"
import PageTitle from "../pageTitle"

export default function Products(params) {
  return (
    <div className="w-full min-h-screen flex flex-col justify-center items-center relative">
      <PageTitle title={"Contact"} />
    </div>
  )
}
